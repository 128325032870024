import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Head from "../components/Head"
import styled from "styled-components"

const PageLayout = styled.div`
  max-width: 1100px;
  margin: 1rem auto;
  /* border: 2px solid black; */
`

const AboutPage = () => {
  return (
    <div>
      <Layout>
        <PageLayout>
          <Head title="about" />
          <h1>About Us</h1>
          <h3>Sold Through Distribution</h3>
          <p>
            Family owned since its inception 1986, RPS Corporation manufactures
            Factory Cat battery powered floor scrubbers and floor sweepers, walk
            behind and ride-on models, in Racine, WI, USA. From our humble
            beginnings with 1 employee and only the Model 34 battery sweeper we
            have grown substantially over the last 35 years, with a strong group
            of executives including the 2nd generation of family ownership, who
            started in the shop building machines as youngsters.
            <br />
            <br />
            Our American workforce, many with over 20 years of service, hand
            build each machine with components sourced over 95% from American
            suppliers. We routinely pay more for these domestically supplied
            components required to build a superior product, quality matters.
            Our machine designs originate and are executed internally with our
            engineering team. With over 100 complete machine designs that have
            been put into production, this allows us to innovate quickly and
            incorporate improvements into our machine offerings. Each new
            generation of machines evolves from prior designs to amplify the
            strengths and correct any weakness. From the first pencil sketch, we
            focus on durability, serviceability, performance, productivity,
            specification, and value.
            <br />
            <br />
            All design prototyping and testing is done in house where we can
            best control quality. Our investment in rapid prototyping technology
            and equipment allows us to turn ideas into functioning prototypes in
            days instead of weeks and months, expediting innovation. Our
            equipment we produce is heavy duty and requires a good amount of
            thick steel in the construction. We use a combination of Stainless
            304 (Austenitic 18/8) and mild steel with e-coating + powder paint
            for corrosion resistance. Steel fabrication is done both in-house
            and from local domestic suppliers with additional cutting, bending,
            welding and other specialty equipment.
            <br />
            <br />
            Our solution and recovery tanks are the best and have complex shapes
            that allow for our compact “tank-in-tank” designs. These designs are
            difficult to mold and are best produced with our in-house equipment
            and specially trained molding team. Running multiple, dual station,
            rotational molding machines allows us to produce very high-quality
            finished parts from raw plastic. Producing our tanks in house allows
            higher quality control and allows us to respond quickly to sales
            spikes and large orders.
            <br />
            <br />
            Education of our distributors and their staff is paramount at
            Factory Cat. We offer specialized hands-on training at our facility
            for the distributor’s staff to assure each of them understand our
            philosophy and have the required knowledge and skills to provide
            exception customer support. We believe that properly educated
            owners, sales staff, technicians, and parts personnel provides a
            better experience for customers who buy our machines.
            <br />
            <br />
            We believe that after sale support is even more important than the
            initial sale. We want you to feel as good as you do first day you
            own a Factory Cat machine in year two, three, four, five and beyond.
            To assure this, our parts department keeps an incredible inventory
            of service parts on hand so they can ship the same day they are
            ordered with virtually no back ordered parts ever. With our central
            location in the US in most cases you will have your parts in 3 days
            or less when shipped via regular UPS.
          </p>
        </PageLayout>
      </Layout>
    </div>
  )
}

export default AboutPage
